import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Show } from "../../hooks/Show";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { isNullOrUndefined } from "../../utils/functionsUtils";
import { MimeTypesEnum } from "../../utils/enumsUtil";

const UploadMasivReschedule = ({
    reduxUploadMasivReschedule,
    successfulUploadRescheduling,
    masivGuidesUploaded,
    reduxDownloadErrors,
    reduxDownloadTemplate
}) => {
    const [showUpload, setShowUpload] = useState(false);
    const [fileError, setFileError] = useState(null);
    const [fileName, setFileName] = useState(null);
    
    const {
        register: registerUpload,
        handleSubmit: handleSubmitUpload,
        watch: watchUpload,
        formState: { errors: errorsUpload },
        reset: resetUpload,
    } = useForm({
        defaultValues: {
            guideOrExternalId: '1'
        }
    });
    const file = useRef({});
    file.current = watchUpload('file', '');

    useEffect(() => {
        if (file.current != null && file.current.length > 0) {
          setFileName(file.current[0].name);
        }
    }, [file.current]);

    useEffect(() => {
        if (successfulUploadRescheduling) {
            Swal.fire(
                (masivGuidesUploaded.errorState ? "Proceso incompleto!": "Proceso exitoso!"),
                    `${masivGuidesUploaded.updated} de ${masivGuidesUploaded.total} Guías/Id Externo reprogramados exitosamente. ` +
                        (masivGuidesUploaded.errorState
                        ? `Alguna(s) guía(s) ingresada(s) no se actualizaron. `
                        : ``) ,
                (masivGuidesUploaded.errorState ? "warning" : "success")
            );
        }
    }, [successfulUploadRescheduling]);

    const handleCloseUpload = () => {
        setFileName(null);
        resetUpload();
        setFileError(null);
        setShowUpload(false);
    };

    const onSubmitUpload = (data) => {
        const tempFile = data.file[0];
        if (!tempFile || MimeTypesEnum.CSV.indexOf(tempFile.type) === -1) 
            return Swal.fire('Archivo errado', 'Señor usuario el archivo debe tener el formato \'CSV UTF-8 delimitado por comas.\'', 'error');

        const reader = new FileReader();
        reader.readAsText(tempFile);
    
        reader.onload = () => {
            const csvData = reader.result;
    
            const isCorrectDelimited = csvData.split('\n')[0].split(';').length > 0;
    
            if (!isCorrectDelimited)
                return Swal.fire('Archivo errado', 'Señor usuario el archivo debe tener el formato \'CSV UTF-8 delimitado por comas.\'', 'error');
            
            const validData = validateData(csvData, data.guideOrExternalId);
            if(!validData){
                Swal.fire('Archivo errado', 'Señor Usuario, el archivo cargado contiene datos errados. Por favor revise el archivo.', 'error');
                return;
            }

            reduxUploadMasivReschedule({
                file: tempFile,
                guideOrExternalId: data.guideOrExternalId
            });
    
            resetUpload();
            setFileName(null);
            setFileError(null);
            setShowUpload(false);
        };
    };

    function removeSpecialCharsAndAccents(str) {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z0-9\s]/g, '');
    }

    const validateData = (data, proccessByGuide) => {
        const rows = data.split('\n');
        const validResolutions = ['1', '2', '3'];
        const phoneValid = /^\s*[0-9+]{1,15}\s*$/;
        const collectionValid = /^\s*[0-9]{1,10}\s*$/;
        const rescheduleDateValid = /^(0[1-9]|[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/;
        const validGuide = [];
        let errors = [
            {
                key: proccessByGuide == '1' ? 'La guia': 'El ID Externo',
                message: 'Razon de error',
            },
        ];
        for (let index = 0; index < rows.length; index++) {
            const columns = rows[index].split(';');
            let [ guide, resolution, comment, scheduleDate, address, phone, collection ] = columns;
            if(
                isNullOrUndefined(rows[index].trim())
                && isNullOrUndefined(guide)
                && isNullOrUndefined(resolution)
                && isNullOrUndefined(comment)
            ){
                continue;
            }

            guide = guide.trim();
            resolution = resolution.trim();
            comment = comment.trim();
            scheduleDate = !isNullOrUndefined(scheduleDate)? scheduleDate.trim(): '';
            address = !isNullOrUndefined(address)? address.trim(): '';
            phone = !isNullOrUndefined(phone)? phone.trim(): '';
            collection = !isNullOrUndefined(collection)? collection.trim(): '';

            if( isNullOrUndefined(guide)  || isNullOrUndefined(resolution)  || isNullOrUndefined(comment) ){
                errors.push({ key: guide, message: `La guia, la solucion y la observacion son requeridas` });
            }
            
            
            if( validResolutions.indexOf(resolution) == -1 ){
                errors.push({ key: guide, message: `El codigo de la solucion es invalida` });
            }

            if( !isNullOrUndefined(scheduleDate)  && !rescheduleDateValid.test(scheduleDate)){
                errors.push({ key: guide, message: `La fecha de reagendamiento es invalida` });
            }


            if( !isNullOrUndefined(phone) && !phoneValid.test(phone) ){
                errors.push({ key: guide, message: `El telefono es invalido` });
            }


            if( validGuide.indexOf(guide) > -1 ){
                errors.push({ key: guide, message: `ID Duplicado` });
                continue;
            }
            comment = removeSpecialCharsAndAccents(comment);
            address = removeSpecialCharsAndAccents(address);
            
            validGuide.push(guide);
        }
        if(errors.length > 1){
            reduxDownloadErrors(errors);
            return false;
        }
        return true;
    }

    const handleUploadOrdersToConfirm = () => {
        setFileName(null);
        resetUpload();
        setFileError(null);
        setShowUpload(true);
    }

    const renderTooltip = (title) => <Tooltip id='button-tooltip'>{title}</Tooltip>;
    const renderHelpTooltip = (title) => <Tooltip id='button-tooltip'>
            <h6>El excel debe tener formato CSV y no debe tener encabezado!</h6>
            <ul style={{listStyle: 'none', padding: 2, margin:0, textAlign: 'left'}}>
                <li>Guías o Id Externo</li>
                <li>
                    Codigos de Solucion:
                    <ul style={{listStyle: 'none', textAlign: 'left'}}>
                        <li>Reprogramar: 1</li>
                        <li>Generar devolucion: 2</li>
                        <li>Ya entregado: 3</li>
                    </ul>
                </li>
                <li>Observaciones</li>
                <li>Fecha de programacion</li>
                <li>Dirección</li>
                <li>Telefono</li>
                <li>Valor a recaudar</li>
            </ul>
            <br/>
            <bold>
                Nota: Los campos del excel deben estar en el orden indicado
            </bold>
        </Tooltip>;
  
    const renderHelpTooltipdownload = (title) => <Tooltip id='button-tooltip'>{title}</Tooltip>;

    const handleButtonClick = () => {
        const payload = {}; // Aquí debes definir el payload
        reduxDownloadTemplate(payload);
    };

    return (
        <>
            <Show when='feature:tab-rescheduling-masiv'>
                <OverlayTrigger
                    placement='top'
                    delay={{ show: 50, hide: 50 }}
                    overlay={renderTooltip('Cargar Reagendamientos')}>
                    <button
                        className='btn btn-primary btn-circle'
                        type='button'
                        onClick={handleUploadOrdersToConfirm}>
                        <i className='fas fa-upload fa-sm'></i>
                    </button>
                </OverlayTrigger>
            </Show>
            <div>
                <Modal
                    show={showUpload}
                    onHide={handleCloseUpload}
                    onEscapeKeyDown={handleCloseUpload}
                    backdrop='static'
                    keyboard={true}>
                    <form onSubmit={handleSubmitUpload(onSubmitUpload)}>
                    <Modal.Header closeButton>
                        <div className="d-flex align-items-center justify-content-between mr-3">
                            <Modal.Title>Cargue masivo</Modal.Title>
                                    <div>
                                    <OverlayTrigger
                                        placement='top'
                                        delay={{ show: 50, hide: 50 }}
                                        overlay={renderHelpTooltipdownload("Descargar plantilla")}>
                                            <button className="btn btn-primary btn-circle ml-2 mr-2" type="button"
                                            onClick={handleButtonClick}>
                                            <i className="fas fa-download fa-sm"></i>
                                            </button>
                                    </OverlayTrigger>
                                    
                                <OverlayTrigger class="center"
                                    placement='bottom'
                                    overlay={renderHelpTooltip("Formato de Cargue")}>
                                    <i class="fas fa-info-circle fa-lg"></i>
                                </OverlayTrigger>
                            </div>
                        </div>
                    </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                <div className="form-group col-md-12">
                                    <div className="form-check form-check-inline">
                                        <input id="uploadByGuia" className="form-check-input" type="radio" value="1" {...registerUpload('guideOrExternalId')}/> 
                                        <label className="form-check-label" htmlFor="uploadByGuia">Guías</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input id="uploadByAddress" className="form-check-input" type="radio" value="2" {...registerUpload('guideOrExternalId')}/> 
                                        <label className="form-check-label" htmlFor="uploadByAddress">ID Externo</label>
                                    </div>
                                </div>
                                <div className="form-group col-md-12">
                                    <div className="custom-file">
                                        <input
                                            type='file'
                                            id='file'
                                            name='file'
                                            {...registerUpload('file', { required: true })}
                                            className={`custom-file-input ${errorsUpload.file && 'is-invalid'}`}
                                        />{' '}
                                        <label
                                            className='custom-file-label'
                                            htmlFor='validatedCustomFile'>
                                            {fileError ? fileError : fileName ? fileName : 'Seleccionar archivo...'}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant='secondary'
                                onClick={handleCloseUpload}>
                                Cancelar
                            </Button>
                            <Button
                                variant='primary'
                                type='submit'>
                                Guardar
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </div>
        </>
    )
}
const mapStateToProps = (state) => ({
    successfulUploadRescheduling: state.reschedulingReducer.successfulUploadRescheduling,
    masivGuidesUploaded: state.reschedulingReducer.masivGuidesUploaded,
})

const mapDispatchToProps = (dispatch) => {
    return {
        reduxUploadMasivReschedule: (payload) => {
            return dispatch({
                type: 'UPLOAD_MASIV_RESCHEDULING_REQUEST',
                value: payload
            })
        },
        reduxDownloadErrors: (payload) => {
            return dispatch({
                type: 'DOWNLOAD_ERRORS_MASIV_RESCHEDULING_REQUEST',
                value: payload
            })
        },
        reduxDownloadTemplate: (payload) => {
            return dispatch({
                type: 'DOWNLOAD_TEMPLATE_MASIV_RESCHEDULING_REQUEST',
                value: payload
            })
        }

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UploadMasivReschedule)