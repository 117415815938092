import React, { forwardRef, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import moment from "moment-timezone";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select'
import { connect } from "react-redux";
import { Button, Modal } from "react-bootstrap";

import Sidebar from "../../components/Navigation/Sidebar";
import Topbar from "../../components/Navigation/Topbar";
import Grid from "../../components/Grid";

import { Show } from "../../hooks/Show";
import { useAuth } from "../../hooks/useAuth";

import { isNullOrUndefined } from '../../utils/functionsUtils';

const Swal = require("sweetalert2");

const Product = ({
  reduxGetProducts,
  products,
  reduxGetProduct,
  product,
  reduxPostProduct,
  rowEdited,
  reduxDeleteProduct,
  rowDeleted,
  reduxPatchProduct,
  rowUpdated,
  reduxResetProductForm,
  reduxGetCategories,
  categories,
  reduxGetShippers,
  shippers,
  reduxDeleteSoftProduct,
  rowDeletedSoft,
  successfulCreateProduct,
  errorsCreateProduct,
  successfulUpdateProduct,
  errorsUpdateProduct,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm();

  const { session } = useAuth();

  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [currentCategory, setCurrentCategory] = useState("");
  const [currentCompany, setCurrentCompany] = useState("");

  const category = useRef({})
  category.current = watch("idCategory", "")

  const company = useRef({});
  company.current = watch("idCompany2", "");

  const companyModal = useRef({});
  companyModal.current = watch("idCompany", "");

  useEffect(() => {
    reduxGetProducts({
      page: 1,
      offset: offset,
      search: "",
    });
    reduxGetCategories({
      page: 1,
      offset: "",
      search: "",
    });
    reduxGetShippers({
      page: 1,
      offset: 10000,
      search: "",
      isActive: true,
    });
  }, []);

  useEffect(() => {
    if (companyModal.current !== '' && companyModal.current !== undefined && companyModal.current !== null) {
      const selected = shippers && Object.keys(shippers).length > 0 && shippers.items.find((e) => e.idCompany === companyModal.current)
      if (!isNullOrUndefined(selected?.description)) {
        setCurrentCompany({ label: selected.description, value: selected.idCompany })
      } else {
        setCurrentCompany({ label: 'Seleccionar', value: null });
        setValue("idCompany", null);
      }
    } else {
      setCurrentCompany({ label: 'Seleccionar', value: null });
      setValue("idCompany", null);
    }
  }, [companyModal.current])

  useEffect(() => {
    if (isNullOrUndefined(category.current)) {
      setCurrentCategory({ label: 'Seleccionar', value: null }); 
      return;
    }

    const selected = categories && Object.keys(categories).length > 0 && categories.items.find((e) => e.idCategory === category.current);

    if (isNullOrUndefined(selected)) {
      setCurrentCategory({ label: 'Seleccionar', value: null });
      return;
    }

    setCurrentCategory({ label: selected.name, value: selected.idCategory })
  }, [category.current])

  useEffect(() => {
    if (rowEdited) {
      setValue("sku", rowEdited.sku);
      setValue("name", rowEdited.name);
      setValue("price", rowEdited.price);
      setValue("weight", rowEdited.weight);
      setValue("description", rowEdited.description);
      setValue("thumbnail", rowEdited.thumbnail);
      setValue("image", rowEdited.image);
      setValue("idCategory", rowEdited.idCategory && rowEdited.idCategory);
      setValue("stock", rowEdited.stock);
      setValue("externalId", rowEdited.externalId);
      setValue("idCompany", rowEdited.idCompany);
      setValue("abbreviation", rowEdited.abbreviation);
      setShow(true);
    }
  }, [rowEdited]);

  useEffect(() => {
    let filters = {
      page: currentPage,
      offset: offset,
      search: search,
    };
    if (company.current) {
      filters = {
        ...filters,
        idCompany: company.current,
      };
    }
    reduxGetProducts(filters);
  }, [currentPage]);

  useEffect(() => {
    if (successfulCreateProduct || successfulUpdateProduct) {
      Swal.fire(
        "Proceso Exitoso",
        `Producto ${successfulCreateProduct ? 'creado' : ''} ${successfulUpdateProduct ? 'guardado' : ''} exitosamente.`,
        "success"
      );
    }

    if (errorsCreateProduct || errorsUpdateProduct) {
      Swal.fire(
        "Proceso Fallido",
        `El producto no ha sido ${errorsCreateProduct ? 'creado' : ''} ${errorsUpdateProduct ? 'guardado' : ''} exitosamente.`,
        "error"
      );
    }
  }, [successfulCreateProduct, successfulUpdateProduct, errorsCreateProduct, errorsUpdateProduct])

  useEffect(() => {
    if (product || rowUpdated || rowDeleted || rowDeletedSoft) {
      if (
        (product && product.message == undefined) ||
        (rowUpdated && rowUpdated.message == undefined) ||
        (rowDeletedSoft && rowDeletedSoft.message == undefined) ||
        rowDeleted
      ) {
        let filters = {
          page: currentPage,
          offset: offset,
          search: search,
        };
        if (company.current) {
          filters = {
            ...filters,
            idCompany: company.current,
          };
        }
        reduxGetProducts(filters);
        reduxResetProductForm();
        setShow(false);
      }
    }
  }, [product, rowUpdated, rowDeleted, rowDeletedSoft]);

  useEffect(() => {
    if (company.current != "" && company.current != undefined) {
      const filters = {
        page: 1,
        offset: 10,
        search: "",
        idCompany: company.current,
      };
      reduxGetProducts(filters);
    } else {
      const filters = {
        page: 1,
        offset: 10,
        search: "",
      };
      reduxGetProducts(filters);
    }
    setCurrentPage(1);
    setOffset(10);
  }, [company.current]);

  const handleClose = () => {
    reduxResetProductForm();
    setShow(false);
  };

  const handleShow = () => {
    reduxGetCategories({
      page: 1,
      offset: offset,
      search: "",
    });
    reduxResetProductForm();
    reset();
    setShow(true);
  };

  const handleOpen = (row) => {
    reduxGetProduct({
      id: row.idProduct,
    });
  };

  const handleActive = (row) => {
    if (!row.isActive) {
      reduxDeleteProduct(row);
    }
  };

  const handleDelete = (row) => {
    reduxDeleteProduct(row);
  };

  const handleDeleteSoft = (row) => {
    reduxDeleteSoftProduct(row);
  };

  const onSubmit = (data) => {
    if (rowEdited) {
      reduxPatchProduct({
        ...data,
        id: rowEdited.idProduct,
      });
    } else {
      reduxPostProduct({
        ...data,
        externalId: data.externalId ? data.externalId : null,
        idCategory: data.idCategory ? data.idCategory : null,
        weight: data.weight ? data.weight : null,
        idCategory: data.idCategory !== "0" ? data.idCategory : null,
        idCompany: data.idCompany,
      });
    }
    reset();
    reduxResetProductForm();
    setShow(false);
  };

  const columns = [
    {
      title: "#",
      render: (rowData) => {
        return <span>{rowData.idProduct}</span>;
      },
      field: 'idProduct',
      searchable: true
    },
    {
      title: "Código de barras",
      render: (rowData) => {
        return <span>{rowData.sku}</span>;
      },
      field: 'sku',
      searchable: true
    },
    {
      title: "Nombre",
      render: (rowData) => {
        return <span>{rowData.name}</span>;
      },
      field: 'name',
      searchable: true
    },
    {
      title: "Abreviatura",
      render: (rowData) => {
        return <span>{rowData.abbreviation}</span>;
      },
      field: 'abbreviation',
    },
    {
      title: "Precio",
      render: (rowData) => {
        return <span>{rowData.price}</span>;
      },
      field: 'price',
      searchable: true
    },
    {
      title: "Categoría",
      render: (rowData) => {
        return <span>{rowData.categoryName}</span>;
      },
      field: 'categoryName',
      searchable: true
    },
    {
      title: "Total Disponible",
      render: (rowData) => {
        return <span>{rowData.stockAvailable}</span>;
      },
    },
    {
      title: "Remitente",
      render: (rowData) => {
        return (
          <span>{rowData.shipperName}</span>
        );
      },
      field: 'shipperName',
      searchable: true
    },
    {
      title: "Acciones",
      render: (rowData) => {
        return (
          <>
            {rowData.isActive ? (
              <>
                <Show when="feature:edit-product">
                  <button
                    title="Editar"
                    className="btn btn-primary btn-sm  btn-circle mr-2"
                    type="button"
                    onClick={(e) => handleOpen(rowData)}
                  >
                    <i className="fas fa-edit fa-xs"></i>
                  </button>
                </Show>
                <Show when="feature:disabled-product">
                  <button
                    title="Desactivar"
                    className="btn btn-danger btn-sm btn-circle mr-2"
                    type="button"
                    onClick={(e) => handleDelete(rowData)}
                  >
                    <i className="fas fa-times-circle fa-xs"></i>
                  </button>
                </Show>
                <Show when="feature:delete-product">
                  <button
                    title="Eliminar"
                    className="btn btn-danger btn-sm btn-circle"
                    type="button"
                    onClick={(e) => handleDeleteSoft(rowData)}
                  >
                    <i className="fas fa-trash-alt fa-xs"></i>
                  </button>
                </Show>
              </>
            ) : (
              <Show when="feature:enabled-product">
                <button
                  title="Activar"
                  className="btn btn-primary btn-sm  btn-circle mr-2"
                  type="button"
                  onClick={(e) => handleActive(rowData)}
                >
                  <i className="fas fa-check-circle fa-xs"></i>
                </button>
              </Show>
            )}
          </>
        );
      },
    },
  ];

  return (
    <div>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar />
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center mb-1">
                <h1 className="h3 mb-0 text-gray-800 mr-3">Productos</h1>
                <Show when="feature:create-product">
                  <button
                    className="btn btn-primary btn-circle"
                    type="button"
                    onClick={handleShow}
                  >
                    <i className="fas fa-plus fa-sm"></i>
                  </button>
                </Show>
              </div>
              <p className="mb-4">Módulo de Administración de productos</p>
              <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Listado de Productos
                  </h6>
                </div>
                <div className="card-body">
                <Show whenNot={["feature:isClient"]}>
                  <div className="row">
                    <div className="form-group col-4">
                      <label htmlFor="idCompany" className="form-label">
                        Remitente
                      </label>
                      <Select
                        isClearable={true}
                        {...register('idCompany2')}
                        id='idCompany2'
                        onChange={(value) => setValue('idCompany2', value?.value || '')}
                        options=
                        {shippers &&
                          Object.keys(shippers).length > 0 &&
                          shippers.items
                            .filter((f) => f.isActive === true)
                            .map((ele) => ({
                                value: ele.idCompany,
                                label: ele.description,
                              }))
                        }
                      />
                    </div>
                  </div>
                  </ Show>
                  <Grid
                    cols={columns}
                    data={
                      products && Object.keys(products).length > 0
                        ? products.items
                        : []
                    }
                    page={
                      products && Object.keys(products).length > 0
                        ? Number(products.page)
                        : currentPage
                    }
                    pages={
                      products && Object.keys(products).length > 0
                        ? Number(products.totalPages)
                        : 0
                    }
                    total={
                      products && products.hasOwnProperty("total")
                        ? products.total
                        : 0
                    }
                    offset={offset}
                    onChangePage={(page) => setCurrentPage(page)}
                    onChangeRange={(value) => {
                      setOffset(value);
                      let filters = {
                        page: 1,
                        offset: value,
                        search: search,
                      }
                      if (company.current) {
                        filters = {
                          ...filters,
                          idCompany: company.current,
                        };
                      }
                      reduxGetProducts(filters);
                    }}
                    defaultValue={search}
                    onChangeSearch={(value) => {
                      setSearch(value);
                      let filters = {
                        page: 1,
                        offset: offset,
                        search: value,
                      }
                      if (company.current) {
                        filters = {
                          ...filters,
                          idCompany: company.current,
                        };
                      }
                      reduxGetProducts(filters);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal
          show={show}
          onHide={handleClose}
          onEscapeKeyDown={handleClose}
          backdrop="static"
          keyboard={true}
          size="lg"
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header closeButton>
              <Modal.Title>
                {rowEdited ? "Editar" : "Nuevo"} Producto
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col s12 m6">
                  <div className="form-group">
                    <label htmlFor="sku" className="form-label">
                      Código de barras *
                    </label>
                    <input
                      id="sku"
                      type="text"
                      className={`form-control form-control-user ${
                        errors.sku && "is-invalid"
                      }`}
                      {...register("sku", { required: true })}
                    />
                    {errors.sku && (
                      <span className="invalid-feedback">
                        El código de barras es requerido
                      </span>
                    )}
                  </div>
                </div>
                <div className="col s12 m6">
                  <div className="form-group">
                    <label htmlFor="name" className="form-label">
                      Nombre *
                    </label>
                    <input
                      id="name"
                      type="text"
                      className={`form-control form-control-user ${
                        errors.name && "is-invalid"
                      }`}
                      {...register("name", { required: true })}
                    />
                    {errors.name && (
                      <span className="invalid-feedback">
                        El nombre es requerido
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col s12 m6">
                  <div className="form-group">
                    <label htmlFor="price" className="form-label">
                      Precio *
                    </label>
                    <input
                      id="price"
                      type="number"
                      className={`form-control form-control-user ${
                        errors.price && "is-invalid"
                      }`}
                      {...register("price", { required: true })}
                    />
                    {errors.price && (
                      <span className="invalid-feedback">
                        El precio es requerido
                      </span>
                    )}
                  </div>
                </div>
                <div className="col s12 m6">
                  <div className="form-group">
                    <label htmlFor="weight" className="form-label">
                      Peso
                    </label>
                    <input
                      id="weight"
                      type="number"
                      className={`form-control form-control-user ${
                        errors.weight && "is-invalid"
                      }`}
                      {...register("weight")}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col s12 m6">
                  <div className="form-group">
                    <label htmlFor="description" className="form-label">
                      Descripción *
                    </label>
                    <input
                      id="description"
                      type="text"
                      className={`form-control form-control-user ${
                        errors.description && "is-invalid"
                      }`}
                      {...register("description", { required: true })}
                    />
                    {errors.description && (
                      <span className="invalid-feedback">
                        La descripción es requerida
                      </span>
                    )}
                  </div>
                </div>
                <div className="col s12 m6">
                  <div className="form-group">
                    <label htmlFor="abbreviation" className="form-label">
                      Abreviatura *
                    </label>
                    <input
                      id="abbreviation"
                      type="text"
                      maxLength={10}
                      className={`form-control form-control-user ${
                        errors.abbreviation && "is-invalid"
                        }`}
                      {...register("abbreviation", {
                        required: 'La abreviatura es requerida',
                        maxLength: {
                          value: 10,
                          message: 'Máximo 10 caractéres'
                        },
                        pattern: {
                          value: /^[A-Za-z0-9]{1,10}$/,
                          message: 'Solo puede contener números y letras'
                        }
                      })}
                    />
                    { errors.abbreviation && (
                      <span className="invalid-feedback">
                        {errors.abbreviation.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col s12 m6">
                  <div className="form-group">
                    <label htmlFor="idCategory" className="form-label">
                      Categoría *
                    </label>
                    <Select
                      isClearable={true}
                      value={currentCategory}
                      {...register('idCategory')}
                      id='idCategory'
                      onChange={(value) => setValue('idCategory', value?.value || '')}
                      options=
                      {categories &&
                        Object.keys(categories).length > 0 &&
                        categories.items
                          .map((ele) => ({
                            value: ele.idCategory,
                            label: ele.name,
                          }))
                      }
                    />
                    {errors.idCategory && (
                      <span className="invalid-feedback">
                        La categoría es requerida
                      </span>
                    )}
                  </div>
                </div>
                {session && session.role.idCompany == null && (
                  <div className="col s12 m5">
                    <div className="form-group">
                      <label htmlFor="idCompany" className="form-label">
                        Remitente *
                      </label>
                      <Select
                        isClearable={true}
                        {...register('idCompany', {required: true})}
                        value={currentCompany}
                        id='idCompany'
                        onChange={(value) => setValue('idCompany', value?.value || '')}
                        options=
                        {shippers &&
                          Object.keys(shippers).length > 0 &&
                          shippers.items
                            .filter((f) => f.isActive === true)
                            .map((ele) => ({
                                value: ele.idCompany,
                                label: ele.description,
                              }))
                        }
                      />
                      {errors.idCompany && (
                        <span className="invalid-feedback">
                          El remitente es requerido
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col s12 m4">
                  <div className="form-group">
                    <label htmlFor="externalId" className="form-label">
                      ID externo
                    </label>
                    <input
                      id="externalId"
                      type="text"
                      className={`form-control form-control-user ${
                        errors.externalId && "is-invalid"
                      }`}
                      {...register("externalId")}
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancelar
              </Button>
              <Button variant="primary" type="submit">
                Guardar
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    products: state.productState.products,
    product: state.productState.product,
    rowEdited: state.productState.rowEdited,
    rowDeleted: state.productState.rowDeleted,
    rowUpdated: state.productState.rowUpdated,
    categories: state.categoryState.categories,
    shippers: state.companyState.clients,
    rowDeletedSoft: state.productState.rowDeletedSoft,
    successfulCreateProduct: state.productState.successfulCreateProduct,
    errorsCreateProduct: state.productState.errorsCreateProduct,
    successfulUpdateProduct: state.productState.successfulUpdateProduct,
    errorsUpdateProduct: state.productState.errorsUpdateProduct,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetProducts: (payload) =>
      dispatch({
        type: "FETCH_PRODUCTS_REQUEST",
        value: payload,
      }),
    reduxPostProduct: (payload) =>
      dispatch({
        type: "CREATE_PRODUCT_REQUEST",
        value: payload,
      }),
    reduxGetProduct: (payload) =>
      dispatch({
        type: "READ_PRODUCT_REQUEST",
        value: payload,
      }),
    reduxDeleteProduct: (payload) =>
      dispatch({
        type: "DELETE_PRODUCT_REQUEST",
        value: payload,
      }),
    reduxPatchProduct: (payload) =>
      dispatch({
        type: "UPDATE_PRODUCT_REQUEST",
        value: payload,
      }),
    reduxResetProductForm: () =>
      dispatch({
        type: "RESET_PRODUCT_FORM",
      }),
    reduxGetCategories: (payload) =>
      dispatch({
        type: "FETCH_CATEGORIES_REQUEST",
        value: payload,
      }),
    reduxGetShippers: (payload) =>
      dispatch({
        type: "FETCH_COMPANIESBYSHIPPER_REQUEST",
        value: payload,
      }),
    reduxDeleteSoftProduct: (payload) =>
      dispatch({
        type: "DELETE_SOFT_PRODUCT_REQUEST",
        value: payload,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Product);
